<template>
  <div class="news-detail">
    <div class="content">
      <div class="title">{{detail.title}}</div>
      <div class="time">{{detail.updateDate}}</div>
      <div class="text" v-html="detail.voteContent" ></div>
    </div>
  </div>
</template>
<script>
import share from "@/components/share";
export default {
  components: { share },
  data() {
    return {
      showList: [],
      detail: {},
    };
  },
  methods: {
    srcList(item) {
      let before = [];
      let after = [];
      let is = false;
      this.showList.forEach((items) => {
        if (items.id == item.id) {
          is = true;
        }
        if (!is) {
          before.push(items.fileUrl);
        }
        if (is) {
          after.push(items.fileUrl);
        }
      });
      return [item.fileUrl, ...after, ...before];
    },
    showItem(item) {
      $("#" + item.id).click();
    },
    getData() {
      this.$sapi
        .get(
          `/api/v1/getYzVoteDynamicDetailById${this.$route.query.id}.json`
        )
        .then((res) => {
          this.detail = res;
        });
    },
  },
  mounted() {
    this.getData();
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
.news-detail {
  padding: 4vw 0 0;
  .content {
    color: #f5f5f5;
    text-align: center;
    .title{
        font-size: 24px;
    }
    .time{
        margin-top: 30px;
        font-size: 18px;
        padding-bottom: 30px;
        border-bottom: 1px solid #666666;
    }
    .text{
        margin-top: 30px;
        min-height: 50vh;
        img{
            max-width: 100%;
        }
    }
  }
}
</style>